import { render, staticRenderFns } from "./AccountBalanceWidget.vue?vue&type=template&id=f3fd8076&scoped=true&"
import script from "./AccountBalanceWidget.vue?vue&type=script&lang=js&"
export * from "./AccountBalanceWidget.vue?vue&type=script&lang=js&"
import style0 from "./AccountBalanceWidget.vue?vue&type=style&index=0&id=f3fd8076&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f3fd8076",
  null
  
)

export default component.exports