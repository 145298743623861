<template>
	<svg
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		:x="_x"
		:y="_y"
		:width="_width"
		:height="_height"
		viewBox="97 30 70 65"
		xml:space="preserve"
		class="connector"
		@click="onMosaicClick(mosaicId)"
	>
		<defs>
			<linearGradient id="native-connector-gradient" x1="0%" y1="0%" x2="100%" y2="100%">
				<stop offset="0%" stop-color="RGB(135, 74, 195)" />
				<stop offset="100%" stop-color="RGB(71, 18, 124)" />
			</linearGradient>
			<linearGradient id="native-connector-shade-gradient" x1="0%" y1="0%" x2="100%" y2="100%">
				<stop offset="0%" stop-color="RGB(89, 0, 98)" />
				<stop offset="100%" stop-color="#FF9999" />
			</linearGradient>
		</defs>
		<g>
			<title> {{ title }} </title>
			<svg
				x="96px"
				y="32px"
			>
				<path
					d="M70.641 15.067C70.641 14.6371 70.5661 14.2258 70.435 13.8333C69.8921 12.1511 68.0385 7.60902 64.9493 3.81464C64.3876 3.04829 63.7323 2.31931 63.0957 1.55296L62.6089 0.992213C62.403 0.767914 62.1783 0.580998 61.9162 0.431466C61.4107 0.132401 60.8115 -0.0171307 60.2124 0.00156086C59.9128 0.0202524 59.632 0.0763274 59.3512 0.169785C59.2201 0.207168 59.089 0.263242 58.958 0.338008C58.958 0.338008 38.5314 11.4221 35.3673 13.067C32.2032 11.4221 11.889 0.281933 11.3834 0.24455C11.1026 0.151092 10.8218 0.0950189 10.5222 0.0763274C9.92307 0.0576358 9.34266 0.207167 8.81842 0.506232C8.5563 0.655765 8.31291 0.84268 8.12568 1.06698L7.63889 1.62773C7.00231 2.3567 6.38446 3.08567 5.78533 3.8894C3.46371 6.5623 0.898693 11.7772 0.374455 13.4034C0.131059 13.9081 0 14.4688 0 15.067C0 16.2819 0.561684 17.3847 1.44165 18.095C1.53527 18.1698 1.61016 18.2445 1.70377 18.3006L1.96589 18.4688L2.35907 18.7305L5.56066 20.7305L11.9264 24.6931L18.3109 28.7118L23.5907 31.9267L23.6656 54.4127L23.703 56.8426L23.7592 58.0762C23.7592 58.2818 23.7592 58.5248 23.7966 58.7865C23.8341 59.2351 23.9277 59.665 24.0962 60.0762C24.4332 60.8052 24.9387 61.4594 25.5753 61.9454C26.2119 62.4314 26.9795 62.7491 27.7659 62.9734C28.5522 63.179 29.2262 63.2912 30.1998 63.4594C33.6448 63.9454 37.1272 63.9454 40.5722 63.4594C41.7892 63.2538 42.1449 63.179 42.9687 62.9921C43.9236 62.7678 44.5415 62.4501 45.178 61.9454C45.8146 61.4594 46.3201 60.8052 46.6571 60.0762C46.8256 59.665 46.9192 59.2351 46.9567 58.7865C46.9754 58.5061 46.9941 58.3005 46.9941 58.0762C46.9941 58.0762 47.275 37.8333 47.1814 31.9454L52.4425 28.7305L58.8269 24.6744L65.2114 20.637L68.413 18.637L68.4504 18.581C69.7236 17.9455 70.641 16.6184 70.641 15.067Z"
					fill="#3F017F"/>
				<path
					d="M26.0654 30.6743C26.0841 28.7865 25.6722 26.9173 24.8484 25.1977C24.0246 23.4968 22.8264 22.0014 21.3473 20.8239C17.1347 17.4781 10.6004 16.2631 4.2534 17.6463C3.8415 17.7211 2.68068 17.0295 2.26878 16.936C2.23134 16.9173 2.17517 16.8613 2.39984 17.0669C2.45601 17.1229 2.56835 17.422 2.62452 17.4594L26.0654 30.8986V30.6743Z"
					fill="#F4A900"
				/>
				<path
					d="M46.6031 15.9076C51.6021 13.9449 55.9271 8.89822 57.9117 2.73001C58.0615 2.28141 58.8478 2.16926 59.2035 1.87019C59.3159 1.77674 58.9414 1.38421 59.0725 1.30945L35.3882 14.9356L35.6877 15.1038C37.3166 16.0571 39.1515 16.6365 41.0612 16.7674C42.9522 16.9169 44.8432 16.6178 46.6031 15.9076Z"
					fill="#78B6E4"
				/>
				<path
					d="M44.2413 31.272C42.6125 32.2252 41.227 33.5336 40.1972 35.085C39.1487 36.6551 38.4934 38.4308 38.2313 40.3C37.4824 45.571 39.6917 51.814 44.0354 56.5803C44.3724 56.9355 44.3537 57.5897 44.4286 58.057C44.4473 58.1878 44.5596 58.1878 44.6907 58.2626L44.7843 30.9916L44.2413 31.272Z"
					fill="#20B5AC"
				/>
				<path
					d="M39.356 41.3277C39.8241 37.6267 42.3142 34.5613 45.7405 32.4866L44.7669 30.9725L44.2426 31.2903C42.6138 32.2436 41.2283 33.552 40.1985 35.1034C39.15 36.6735 38.4948 38.4492 38.2326 40.3183C37.5586 44.9538 39.2062 50.3557 42.5763 54.8416C39.9926 50.5052 38.7756 45.608 39.356 41.3277Z"
					fill="#3F017F"
				/>
				<path
					d="M46.828 14.5062C43.3643 15.9268 39.4887 15.2912 35.9875 13.3286L35.145 14.9174L35.688 15.2352C37.3169 16.1697 39.133 16.7305 41.0053 16.8613C42.8775 16.9922 44.7685 16.6931 46.5097 16.0015C50.7973 14.338 55.4592 9.83331 57.706 4.84267C57.7434 4.74921 57.369 4.338 57.0132 5.08566C54.5231 9.45948 50.8722 12.8987 46.828 14.5062Z"
					fill="#3F017F"
				/>
				<path
					d="M19.9955 21.6082C22.8976 23.9634 24.2082 27.1409 24.1333 31.1222L25.9307 31.683L26.4923 31.0662C26.6047 27.2718 24.9383 22.5241 21.9614 20.0942C18.4977 17.1783 11.2146 16.2064 6.32792 16.9353C6.1407 16.9727 6.04708 17.2531 7.33895 17.197C12.4315 17.2718 16.6629 18.8232 19.9955 21.6082Z"
					fill="#3F017F"
				/>
				<path
					d="M35.3674 61.3848C32.8398 61.4035 30.3122 61.0671 27.8782 60.4129C27.354 60.2634 26.9047 59.9456 26.5677 59.5157C26.2494 59.0858 26.0621 58.5624 26.0621 58.0204V30.6746C26.0809 28.7868 25.6502 26.9176 24.8452 25.198C24.0214 23.4971 22.8231 22.0017 21.344 20.8242C17.1314 17.4784 10.5971 16.2634 4.25012 17.6466C3.61354 17.7961 2.93952 17.684 2.37784 17.3288C1.81616 16.9737 1.42298 16.413 1.2732 15.7774C1.16086 15.3102 1.19831 14.8055 1.36681 14.3569C2.17189 12.0765 3.1642 9.88961 4.34373 7.77746C5.59816 5.57186 7.15214 3.57186 8.94953 1.79616C9.34271 1.42233 9.84822 1.17934 10.3725 1.12327C10.9154 1.06719 11.4584 1.16065 11.9265 1.44102L35.0491 14.7681L35.6669 15.1232C37.2958 16.0765 39.1306 16.656 41.0403 16.7868C42.9313 16.9176 44.8223 16.6186 46.5823 15.9083C51.5813 13.9457 55.9062 8.89896 57.8908 2.73074C57.9844 2.41299 58.153 2.13261 58.3589 1.87093C58.5649 1.62794 58.827 1.42233 59.1265 1.25411C59.4261 1.10458 59.7444 1.01112 60.0627 0.973736C60.381 0.936352 60.718 0.973735 61.0363 1.08588C61.5043 1.23542 61.8975 1.4971 62.2158 1.87093C63.7885 3.7027 65.1927 5.68401 66.4284 7.75877C67.7203 9.94568 68.6939 12.2821 69.3304 14.7307C69.4615 15.2541 69.4053 15.8148 69.1994 16.3008C68.9934 16.8055 68.619 17.2167 68.1509 17.4784L44.8785 30.8989L44.2419 31.2728C42.6131 32.226 41.2276 33.5344 40.1978 35.0858C39.1493 36.6559 38.4941 38.4316 38.2319 40.3008C37.483 45.5718 39.6923 51.8148 44.036 56.5811C44.3356 56.9176 44.5415 57.3101 44.6351 57.7587C44.7287 58.1886 44.6913 58.6559 44.5602 59.0671C44.4104 59.497 44.1483 59.8708 43.8113 60.1512C43.4743 60.4316 43.0624 60.6372 42.6131 60.712C40.2166 61.1232 37.7826 61.3475 35.3674 61.3848Z"
					fill="white"
				/>
			</svg>
		</g>
	</svg>
</template>

<script>
import GraphicComponent from './GraphicComponent.vue';

export default {
	extends: GraphicComponent,

	props: {
		width: {
			type: Number,
			default: 261.333
		},

		height: {
			type: Number,
			default: 131.313
		}
	},

	computed: {
		title () {
			return this.getTranslation('mosaic') + ': ' + this.nativeMosaicAliasName;
		},

		mosaicId () {
			return this.nativeMosaicId;
		},

		aliasName () {
			return this.nativeMosaicAliasName;
		}
	},

	methods: {
		click () {
			this.$emit('click', this.mosaicId);
		}
	}
};
</script>

<style lang="scss" scoped>
.connector {
    cursor: pointer;
}

.connector-body {
    font-size: 18px;
    font-weight: bold;
}
</style>
